import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';

const Formulario = () => {
  const [titulo, setTitulo] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [urlYoutube, setUrlYoutube] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [tags, setTags] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const id_user = localStorage.getItem('id_user');
  const organizacion = localStorage.getItem('organizacion');
  
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles(files);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setTags(value);
    searchUsers(value);
  };

  const handleSelectUser = (user) => {
    setSelectedUsers([...selectedUsers, user]);
    setTags('');
    setSearchResults([]);
  };

  const searchUsers = (searchText) => {
    const data = {'type':'GetCandidatosSearch','texto':searchText}
    fetch('https://novomediaapp.azurewebsites.net/InfoCandidatosProv.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(data => {
        setSearchResults(data.data);
      })
      .catch(error => console.error('Error de red:', error));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!titulo || !descripcion) {
      alert('Por favor completa todos los campos.');
      return;
    }
  
    const tagsString = selectedUsers.map(user => user.value).join(',');
    
    const formData = new FormData();
    formData.append('type', 'SavePost');
    formData.append('titulo', titulo);
    formData.append('descripcion', descripcion);
    formData.append('urlYoutube', urlYoutube);
    formData.append('IdUser', id_user);
    formData.append('organizacion', organizacion);
    formData.append('tags', tagsString);

    selectedFiles.forEach((file, index) => {
      formData.append(`file${index + 1}`, file);
    });
  
    try {
      const response = await fetch('https://novomediaapp.azurewebsites.net/SavePost.php', {
        method: 'POST',
        body: formData
      });
      if (response.ok) {
        setTitulo('');
        setDescripcion('');
        setUrlYoutube('');
        setSelectedFiles([]);
      } else {
        console.error('Error al enviar los datos.');
      }
    } catch (error) {
      console.error('Error de red:', error);
    }
  };
  
  const handleRemoveUser = (userToRemove) => {
    const updatedUsers = selectedUsers.filter(user => user.id !== userToRemove.id);
    setSelectedUsers(updatedUsers);
  };

  return (
    <form onSubmit={handleSubmit} style={styles.form}>
      <input
        type="text"
        placeholder="Título"
        value={titulo}
        onChange={(e) => setTitulo(e.target.value)}
        style={styles.input}
        required
      />
      <textarea
        placeholder="Descripción"
        value={descripcion}
        onChange={(e) => setDescripcion(e.target.value)}
        style={styles.textarea}
        required
      ></textarea>
      <input
        type="url"
        placeholder="URL de YouTube"
        value={urlYoutube}
        onChange={(e) => setUrlYoutube(e.target.value)}
        style={styles.input}
      />
      <input
        type="text"
        placeholder="Tags"
        value={tags}
        onChange={handleChange}
        style={styles.input}
      />
      <div style={{ top: '100%', left: '0', width: '100%', zIndex: '999', background: '#fff', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', borderRadius: '5px' }}>
        {searchResults.map((user, index) => (
          <div key={index} style={{ display: 'flex', alignItems: 'center', padding: '8px', cursor: 'pointer', borderBottom: '1px solid #eee' }} onClick={() => handleSelectUser(user)}>
            <img src={user.foto} alt={user.nick} style={{ width: '30px', height: '30px', borderRadius: '5%', marginRight: '8px' }} />
            <span style={{ fontSize: '14px' }}>{user.nick}</span>
          </div>
        ))}
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {selectedUsers.map((user, index) => (
          <div key={index} style={{ display: 'flex', alignItems: 'center', padding: '4px', background: '#f0f0f0', borderRadius: '5px', marginRight: '4px', marginBottom: '4px' }}>
            <img src={user.foto} alt={user.nick} style={{ width: '20px', height: '20px', borderRadius: '5%', marginRight: '4px' }} />
            <span style={{ fontSize: '12px' }}>{user.nick}</span>
            <FaTimes style={{ marginLeft: '4px', cursor: 'pointer' }} onClick={() => handleRemoveUser(user)} />
          </div>
        ))}
      </div>
      <input
        type="file"
        multiple
        id="file"
        name="file"
        onChange={handleFileChange}
        accept=".jpg,.png"
        style={{ display: 'none' }}
      />
      <label htmlFor="file" style={styles.fileInput}>Seleccionar Imágenes</label>
      {selectedFiles.length > 0 && (
        <ul>
          {selectedFiles.map((file, index) => (
            <li key={index}>{file.name}</li>
          ))}
        </ul>
      )}
      <button type="submit" style={styles.button}>Enviar</button>
    </form>
  );
};

const styles = {
  form: {
    width: '60%',
    margin: '0 auto', // Centra horizontalmente
    paddingTop: '5%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  input: {
    width: '100%',
    padding: '10px',
    marginBottom: '10px',
    border: '1px solid black',
    borderRadius: '5px',
  },
  textarea: {
    width: '100%',
    height: '120px',
    padding: '10px',
    marginBottom: '10px',
    border: '1px solid black',
    borderRadius: '5px',
  },
  fileInput: {
    width: '100%',
    padding: '10px',
    marginBottom: '10px',
    border: '1px solid black',
    borderRadius: '5px',
    backgroundColor: 'lightgray',
    textAlign: 'center',
    cursor: 'pointer',
  },
  button: {
    width: '100%',
    padding: '15px',
    backgroundColor: 'black',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};

export default Formulario;
