import React, { useEffect,useState } from 'react';
import Modal from 'react-modal';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Sidebar from './Sidebar';
import deembysImg from './imagenes/dem.png'
import seguidores from './imagenes/seguidores.png';
import camara from './imagenes/cam.png';
import etiqueta from './imagenes/etiqueta.png';
import points from './imagenes/point.png';
import news from './imagenes/news.png';
import bluefill from './imagenes/bluefill.png';
import blue from './imagenes/blue.png';
import ReactPlayer from 'react-player';
import Swal from 'sweetalert2'

const PanelUsuario = ({ candidato,deembys,setFiltro,filtro,getDeembysDiarios }) => {
  const [videoUrl, setVideoUrl] = useState('');
  const [tipo, setTipo] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpenB, setModalIsOpenB] = useState(false);
  const [modalIsOpenIMG, setModalIsOpenIMG] = useState(false);
  const [modalIsOpenNew, setModalIsOpenNew] = useState(false);
  const [reels, setReels] = useState([]);
  const [reel, setReel] = useState([]);
  const [categoria, setCategoria] = useState([]);
  const [siguiendo, setSiguiendo] = useState(candidato.siguiendo);
  const [imageError, setImageError] = useState(false);
  const id_user = localStorage.getItem('id_user');
    
  useEffect(() => {
    getReels(1);
    console.log(candidato.siguiendo);
  }, []);

  const openModal = (url,reelSelect) => {
    setVideoUrl(url);
    setReel(reelSelect);
    setModalIsOpen(true);
    reelSelect.presidencia==1?setCategoria(0):reelSelect.gobernatura==1?setCategoria(1)
    :reelSelect.senaduria==1?setCategoria(2):reelSelect.diputacion==1?setCategoria(3):setCategoria(4);
  };
  
  const openModalIMG = (reelSelect) => {
    setReel(reelSelect);
    setModalIsOpenIMG(true);
  };
  
  const openModalNew = (reelSelect) => {
    setReel(reelSelect);
    setModalIsOpenNew(true);
  };

  const openModalB = (tipo) => {
    setTipo(tipo);
    setModalIsOpenB(true);
  };

  const likeReel = () => {
    const data = {
      type: 'LikeReel',
      id:reel.id,
      status:reel.like,
      id_user,
      organizacion:reel.organizacionId,
      presidencia:reel.presidencia,
      gobernatura:reel.gobernatura,
      senaduria:reel.senaduria,
      diputacion:reel.diputacion,
      alcaldiav:reel.alcaldiav,
    };
    fetch('https://novomediaapp.azurewebsites.net/InfoCandidatosProv.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          getReels(1);
          getDeembysDiarios();
          Swal.fire({
            title: "Deemby",
            text: data.message,
            icon: "success"
          });
        } else {
          Swal.fire({
            title: "Deemby",
            text: data.message,
            icon: "error"
          });
        }
        
      })
      .catch((error) => console.error('Error de red:', error));
  }

  const closeModal = () => {
    setModalIsOpen(false);
    setModalIsOpenB(false);
    setModalIsOpenIMG(false);
    setModalIsOpenNew(false);
    setVideoUrl('');
    setTipo('');
    setReel([]);
  };
  
  const getYoutubeThumbnail = (videoUrl) => {
    const videoIdIndex = videoUrl.indexOf('v=');
    if (videoIdIndex !== -1) {
      const videoId = videoUrl.substring(videoIdIndex + 2);
      return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
    } else {
      return 'https://example.com/default-thumbnail.jpg';
    }
  };

  const SendMessage = () => {
    alert('El usuario esta inactivo'); 
  }
  const NotMessage = () => {
    alert('El usuario esta inactivo'); 
  }
  const DeleteMessage = () => {
    alert('El usuario esta inactivo'); 
  }

  const seguir_Usuario = (idSeguir, status) => {
    const data = {
      type: 'Seguir_Usuario',
      id_usuario: id_user,
      seguir: idSeguir,
      status: status,
    };
    fetch('https://novomediaapp.azurewebsites.net/InfoCandidatosProv.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if(siguiendo===1){
          setSiguiendo(0);
        }else{
          setSiguiendo(1);
        }
      })
      .catch((error) => console.error('Error de red:', error));
  };

  const getReels = (tipo) => {
    const data = {
      type: 'GetReels',
      id_candidato:candidato.user_id, 
      id_infoC:candidato.id, 
      tipo,
      id_user
    };
    fetch('https://novomediaapp.azurewebsites.net/InfoCandidatosProv.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        setReels(data.data);
      })
      .catch((error) => console.error('Error de red:', error));
  };

  const getYoutubeVideoId = (videoUrl) => {
    const videoIdIndex = videoUrl.indexOf('v=');
    if (videoIdIndex !== -1) {
      return videoUrl.substring(videoIdIndex + 2);
    } else {
      return '';
    }
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const calcularTiempoPasado = (fecha) => {
    const fechaPublicacion = new Date(fecha);
    const fechaActual = new Date();

    const diferencia = fechaActual - fechaPublicacion;
    const segundos = Math.floor(diferencia / 1000);
    const minutos = Math.floor(segundos / 60);
    const horas = Math.floor(minutos / 60);
    const dias = Math.floor(horas / 24);

    return { dias, horas: horas % 24, minutos: minutos % 60 };
  };

  const handleImageError = () => {
    setImageError(true);
  };

  return (
    <div style={styles.container}>
      <>
        <div>
          <Sidebar deembys={deembys} setFiltro={setFiltro} filtro={filtro}/>
        </div>
        <div>
          <div className="row">
            <div className="col-md-2">
              <img src={candidato.foto} alt="Foto de perfil" style={{height:'110px',width:'110px',borderRadius:'5%'}}/>
              <br/><br/>
            </div>
            <div className="col-md-8">
              <p style={styles.puesto}>{candidato.puesto}-{candidato.partido}</p>
              <p style={styles.nombre}>{candidato.nombre}</p>
              <p style={styles.apellido}>{candidato.apellidos}</p>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={seguidores} alt="Imagen de persona" style={{ width: '3%' }} /><span style={{ color: '#20C997' }}>{candidato.seguidores}</span>
                <img src={deembysImg} alt="Imagen de persona" style={{ width: '36px', paddingLeft: '3%'  }} /><span style={{ color: '#20C997' }}>{candidato.dembys}</span>
                {candidato.facebook && (
                  <a href={candidato.facebook} target="_blank" rel="noopener noreferrer" style={{ paddingLeft: '3%' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
                      <path d="M18 2H15C13.6739 2 12.4021 2.52678 11.4645 3.46447C10.5268 4.40215 10 5.67392 10 7V10H7V14H10V22H14V14H17L18 10H14V7C14 6.73478 14.1054 6.48043 14.2929 6.29289C14.4804 6.10536 14.7348 6 15 6H18V2Z" stroke="#235A7B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                  </a>
                )}
                {candidato.twitter && (
                  <a href={candidato.twitter} target="_blank" rel="noopener noreferrer" style={{ paddingLeft: '3%' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
                      <path d="M14.2737 10.1635L23.2022 0H21.0872L13.3312 8.82302L7.14122 0H0L9.36257 13.3432L0 23.9999H2.11503L10.3002 14.6805L16.8387 23.9999H23.9799M2.87839 1.56189H6.12767L21.0856 22.5148H17.8355" stroke="#235A7B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                  </a>
                )}
                {candidato.instagram && (
                  <a href={candidato.instagram} target="_blank" rel="noopener noreferrer" style={{ paddingLeft: '3%' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
                      <path d="M17 2H7C4.23858 2 2 4.23858 2 7V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V7C22 4.23858 19.7614 2 17 2Z" stroke="#235A7B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                      <path d="M16.0002 11.37C16.1236 12.2022 15.9815 13.0522 15.594 13.799C15.2065 14.5458 14.5933 15.1514 13.8418 15.5297C13.0903 15.9079 12.2386 16.0396 11.408 15.9059C10.5773 15.7723 9.80996 15.3801 9.21503 14.7852C8.62011 14.1902 8.22793 13.4229 8.09426 12.5922C7.9606 11.7615 8.09226 10.9099 8.47052 10.1584C8.84878 9.40685 9.45438 8.79374 10.2012 8.40624C10.948 8.01874 11.7979 7.87658 12.6302 8C13.4791 8.12588 14.265 8.52146 14.8719 9.1283C15.4787 9.73515 15.8743 10.5211 16.0002 11.37Z" stroke="#235A7B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                      <path d="M17.5 6.5H17.51" stroke="#235A7B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                  </a>
                )}
                <img src={candidato.logo_partido} alt="Imagen de persona" style={{ height: '20px',width:'auto', paddingLeft: '3%' }} />
              </div>

            </div>
            <div className="col-md-2">
              {siguiendo===1 || siguiendo==='1'? 
                <button style={styles.buttonSiguiendo} onClick={() => seguir_Usuario(candidato.id, 0)}>Siguiendo</button> 
                : 
                <button style={styles.buttonSeguir}  onClick={() => seguir_Usuario(candidato.id, 1)}>Seguir</button>
              }
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-4">
                  <button style={styles.buttonSeguir}  onClick={() => openModalB(1)}>Historia Profesional</button>
                </div>
                <div className="col-md-4">
                  <button style={styles.buttonSeguir} onClick={() => openModalB(2)}>Trayectoria Politica</button>
                </div>
                <div className="col-md-4">
                  <button style={styles.buttonSeguir} onClick={() => openModalB(3)}>Mensaje<br/></button>
                </div>
              </div><br/>
              <div className="row">
                <div className="col-md-4">
                  <button style={styles.buttonSeguir}  onClick={() => openModalB(4)}>Propuesta 1</button>
                </div>
                <div className="col-md-4">
                  <button style={styles.buttonSeguir} onClick={() => openModalB(5)}>Propuesta 2</button>
                </div>
                <div className="col-md-4">
                  <button style={styles.buttonSeguir} onClick={() => openModalB(6)}>Propuesta Género</button>
                </div>
              </div>
            </div>
            <br/>
            <div className="col-md-12" style={{marginTop:'5%'}}>
              <div className="row justify-content-center">
                  <div className="col-md-3 text-center" onClick={() => getReels(1)}>
                      <img src={camara} style={{ width: '20%',cursor:'pointer' }} alt='Videos'/>
                      <p>Video</p>
                  </div>
                  <div className="col-md-3 text-center" onClick={() => getReels(2)}>
                    <img src={points} style={{ width: '20%' ,cursor:'pointer'}} alt='Imagenes'/>
                    <p>Imagenes</p>
                  </div>
                  <div className="col-md-3 text-center" onClick={() => getReels(3)}>
                    <img src={etiqueta} style={{ width: '20%' ,cursor:'pointer'}} alt='Etiquetas'/>
                    <p>Etiquetas</p>
                  </div>
                  <div className="col-md-3 text-center" onClick={() => getReels(4)}>
                    <img src={news} style={{ width: '20%' ,cursor:'pointer'}} alt='Punto Libre'/>
                    <p>Punto Libre</p>
                  </div>
              </div>
          </div>
          </div>
          <div className="row" style={{paddingTop:'5%'}}>
            {reels.map((reel, index) => (
              reel.idUsuario>0?
              <div className="col-md-12" style={{paddingTop:'2%',cursor:'pointer',border:1}} onClick={() => openModalNew(reel)}>
                <h4>{reel.titulo}</h4>
                <p>{reel.descripcionCorta}</p>
                <i>Autor | {reel.autor}</i><br/>
                <hr/>
              </div>
              :reel.link==='' || reel.link === null?
              <div className="col-md-4" style={{paddingTop:'5%',cursor:'pointer'}} onClick={() => openModalIMG(reel)}>
                <img src={'https://novomediaapp.azurewebsites.net/publicacionesIC/'+reel.id+'_1.jpg'} alt={'imagen' +reel.id} style={styles.image} />
              </div>
              :
              <div className="col-md-4" style={{paddingTop:'5%',cursor:'pointer'}} onClick={() => openModal(reel.link,reel)}>
                <img src={getYoutubeThumbnail(reel.link)} alt={`YouTube Thumbnail ${index}`} style={styles.image} />
              </div>
            ))}
          </div>
        </div>
      </>
      <Modal isOpen={modalIsOpenNew} onRequestClose={closeModal} style={{content: {
          width: '75%', 
          height: '550px', 
          overflowY: tipo===3?'':'scroll', 
          margin: 'auto' 
        }
      }}>
       <div>
          <br/>
          <h2>{reel.titulo}</h2>
          <h5>{reel.descripcionCorta}</h5>
          {imageError ? (
            ''
          ) : (
            <img
              src={`https://novomediaapp.azurewebsites.net/assents/publicaciones/${reel.id}.jpg`}
              alt="Imagen principal"
              onError={handleImageError}
              style={{ height: 'auto', width: '100%'}}
            />
          )}
          <div dangerouslySetInnerHTML={{ __html: reel.articulo }} />
          <i>Autor | {reel.autor}</i><br/>
          <i>{`Hace ${calcularTiempoPasado(reel.fecha).dias} días, ${calcularTiempoPasado(reel.fecha).horas} horas y ${calcularTiempoPasado(reel.fecha).minutos} minutos`}</i>
        </div>
      </Modal>

      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={{content: {
          width: '60%', 
          height: '550px', 
          overflowY: tipo===3?'':'scroll', 
          margin: 'auto' 
        }
      }}>
        <ReactPlayer url={`https://www.youtube.com/watch?v=${getYoutubeVideoId(videoUrl)}`} playing controls width="100%" height="300px" />
        <br/>
        <b>{reel.titulo }</b> <img src={reel.like==='1'?bluefill:blue} 
        onClick={() => likeReel()} style={{height:'30px',cursor:'pointer'}} hidden={deembys[categoria] && reel.like==='0' && deembys[categoria].cantidad_registros > 0}/>
        <br/><br/>
        <p>{reel.nota}</p>
      </Modal>

      <Modal isOpen={modalIsOpenIMG} onRequestClose={closeModal} style={{content: {
          width: '50%', 
          height: '550px',
          overflowY: tipo===3?'':'scroll',
          margin: 'auto'
        }
      }}>
        <Carousel 
          responsive={responsive}
          arrows={false}
          showDots={true}
          >
          {Array.from({ length: reel.imagenes }).map((_, index) => (
            <div key={index}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <img src={'https://novomediaapp.azurewebsites.net/publicacionesIC/' + reel.id + '_' + (index + 1) + '.jpg'} alt={`Imagen ${index + 1}`} 
                style={{width: '70%'}}/>
              </div>
            </div>
          ))}
        </Carousel>
        <br/>
        <b>{reel.titulo } </b> <img src={reel.like==='1'?bluefill:blue} 
        onClick={() => likeReel()} style={{height:'30px',cursor:'pointer'}} 
        hidden={deembys[categoria] && reel.like==='0' && deembys[categoria].cantidad_registros > 0}/>
        <br/><br/>
        <p>{reel.nota}</p>
      </Modal>

      <Modal isOpen={modalIsOpenB} onRequestClose={closeModal} style={{content: {
          width: '50%', // Ajusta el ancho del modal según tu preferencia
          height: '350px', // Altura fija del modal
          overflowY: tipo===3?'':'scroll', // Agrega un scrollbar vertical
          margin: 'auto' // Centra el modal horizontalmente
        }
      }}>
          {tipo===1?
          <div><h2>Historia Profesional </h2>
            <p>{candidato.historia_profesional}</p></div>
          :tipo===2? 
          <div><h2>Trayectoria Política</h2>
          <p>{candidato.trayectoria_politica}</p></div>
          :tipo===3? 
          <div>
            <div>
              <button style={styles.buttonMensajes} onClick={SendMessage}>Nuevo Mensaje</button><br/><br/>
            </div>
            <div>
              <button style={styles.buttonMensajes} onClick={NotMessage}>Silenciar Mensajes</button><br/><br/>
            </div>
            <div>
              <button style={styles.buttonCancela} onClick={DeleteMessage}>Eliminar Mensajes</button><br/><br/>
            </div>
            <div>
              <button style={styles.buttonSeguir} onClick={closeModal}>Cancelar</button>
            </div>
          </div> 
          :tipo===4? 
          <div><h2>Propuesta 1</h2>
          <p>{candidato.propuesta_1}</p></div>
          :tipo===5? 
          <div><h2>Propuesta 2</h2>
          <p>{candidato.propuesta_2}</p></div>
          : <div><h2>Propuesta Genero</h2>
          <p>{candidato.propuesta_genero}</p></div>}
        
      </Modal>
    </div>
  );
};

const styles = {
  buttonSeguir: {
    width: '100%',
    padding: '15px',
    borderRadius: '5px',
    backgroundColor: 'white',
    color: '#01456A',
    border: '1px solid #01456A',
    cursor: 'pointer',
    marginRight: '10px',
  },
  buttonMensajes: {
    width: '100%',
    padding: '15px',
    borderRadius: '5px',
    backgroundColor: 'white',
    color: '#01456A',
    border: '1px solid white',
    cursor: 'pointer',
    marginRight: '10px',
  },
  buttonCancela: {
    width: '100%',
    padding: '15px',
    borderRadius: '5px',
    backgroundColor: 'white',
    color: 'red',
    border: '1px solid white',
    cursor: 'pointer',
    marginRight: '10px',
  },
  image:{
    width: '100%'
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '30% 70%',
    paddingTop:'2%'
  },
  puesto: {
    margin: 0,
    color: '#aaa',
  },
  nombre: {
    margin: 0,
    fontWeight: 'bold',
  },
  apellido: {
    margin: 0,
  },
  buttonSiguiendo: {
    width: '100%',
    padding: '15px',
    borderRadius: '5px',
    backgroundColor: '#20C997',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    marginRight: '10px',
  },
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#000',
    border: 'none',
    padding: 0,
  },
};

export default PanelUsuario;