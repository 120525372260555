import React, { useState } from 'react';
import Modal from 'react-modal';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ReactPlayer from 'react-player';
import bluefill from './imagenes/bluefill.png';
import blue from './imagenes/blue.png';
import Swal from 'sweetalert2'

const StoryCarouselDes = ({ publicaciones,getPublicaciones,getDeembysDiarios,deembys }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [reel, setPublicacion] = useState('');
  const [categoria, setCategoria] = useState([]);
  const id_user = localStorage.getItem('id_user');

  const openModal = (url,publicacion) => {
    setVideoUrl(url);
    setModalIsOpen(true);
    setPublicacion(publicacion);
    publicacion.presidencia==1?setCategoria(0):publicacion.gobernatura==1?setCategoria(1)
    :publicacion.senaduria==1?setCategoria(2):publicacion.diputacion==1?setCategoria(3):setCategoria(4);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setVideoUrl('');
  };

  const getYoutubeThumbnail = (videoUrl) => {
    const videoIdIndex = videoUrl.indexOf('v=');
    if (videoIdIndex !== -1) {
      const videoId = videoUrl.substring(videoIdIndex + 2);
      return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
    } else {
      return 'https://example.com/default-thumbnail.jpg';
    }
  };

  const getYoutubeVideoId = (videoUrl) => {
    const videoIdIndex = videoUrl.indexOf('v=');
    if (videoIdIndex !== -1) {
      return videoUrl.substring(videoIdIndex + 2);
    } else {
      return '';
    }
  };

  const likeReel = () => {
    const data = {
      type: 'LikeReel',
      id:reel.id,
      status:reel.like,
      id_user,
      organizacion:reel.organizacionId,
      presidencia:reel.presidencia,
      gobernatura:reel.gobernatura,
      senaduria:reel.senaduria,
      diputacion:reel.diputacion,
      alcaldiav:reel.alcaldiav,
    };
    fetch('https://novomediaapp.azurewebsites.net/InfoCandidatosProv.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          getPublicaciones(id_user);
          getDeembysDiarios();
          Swal.fire({
            title: "Deemby",
            text: data.message,
            icon: "success"
          });
        } else {
          Swal.fire({
            title: "Deemby",
            text: data.message,
            icon: "error"
          });
        }
        
      })
      .catch((error) => console.error('Error de red:', error));
  }

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4.3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4.3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 4.3,
    },
  };

  const responsive2 = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="square-carousel">
      <Carousel responsive={responsive} >
        {publicaciones.map((publicacion, index) => (
          <div key={index} style={styles.rectangle} onClick={() => openModal(publicacion.link,publicacion)}>
            <img src={publicacion.imagenes>0?'https://novomediaapp.azurewebsites.net/publicacionesIC/' + publicacion.id + '_' + (1) + '.jpg':getYoutubeThumbnail(publicacion.link)} alt={`YouTube Thumbnail ${index}`} style={styles.image} />
            <div style={overlayStyle}>
              <img src={publicacion.foto}
              alt="Foto del autor" style={{ width: '40px', height: '40px', margin: '10px', position: 'absolute', top: 0, left: 0, borderRadius:'5px' }} />
              <div style={{ position: 'absolute', bottom: 0, color: '#fff',paddingLeft:'6%'}}>
                <p style={{fontSize:'17px'}}>{publicacion.titulo}</p> <p style={{fontSize:'13px'}}>{publicacion.titulo}</p>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Video Modal" style={{content: {
          width: '50%', 
          height: '550px',
          overflowY:'scroll',
          margin: 'auto'
        }
      }}>
        {reel.imagenes>0?
          <>
            <Carousel 
            responsive={responsive2}
            arrows={false}
            showDots={true}
            >
              {Array.from({ length: reel.imagenes }).map((_, index) => (
                <div key={index}>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <img src={'https://novomediaapp.azurewebsites.net/publicacionesIC/' + reel.id + '_' + (index + 1) + '.jpg'} alt={`Imagen ${index + 1}`} 
                    style={{width: '70%'}}/>
                  </div>
                </div>
              ))}
            </Carousel>
            <br/>
            <img src={reel.like==='1'?bluefill:blue} 
            onClick={() => likeReel()} 
            style={{height:'30px', cursor:'pointer', float:'right'}} 
            hidden={deembys[categoria] && reel.like==='0' && deembys[categoria].cantidad_registros > 0} />

            <br/>
            <b>{reel.titulo } </b><br/>
            <p>{reel.nota}</p>
          </>:
          <>
            <ReactPlayer url={`https://www.youtube.com/watch?v=${getYoutubeVideoId(videoUrl)}`} playing controls width="100%" height="300px" />
            <br/>
            <img src={reel.like==='1'?bluefill:blue} 
            onClick={() => likeReel()} 
            style={{height:'30px', cursor:'pointer', float:'right'}} 
            hidden={deembys[categoria] && reel.like==='0' && deembys[categoria].cantidad_registros > 0}/>
            <br/>
            <b>{reel.titulo }</b> <br/>
            <p>{reel.nota}</p>
          </>
        }
        
      </Modal>
    </div>
  );
};

const overlayStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
};

const styles = {
  rectangle: {
    width: '170px',
    height: '240px',
    borderRadius: '5px',
    overflow: 'hidden',
    position: 'relative',
    cursor: 'pointer',
  },
  image: {
    width: '100%',
    height: '120%',
    objectFit: 'cover',
  },
};

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#000',
    border: 'none',
    padding: 0,
  },
};

export default StoryCarouselDes;
