import React, { useState, useEffect } from 'react';
import deembysIMG from './imagenes/dem.png'
import seguidores from './imagenes/seguidores.png'
import { useNavigate } from 'react-router-dom'; 
import Sidebar from './Sidebar';

const Top10 = ({ setcandidato,filtro,setFiltro,setFiltroMr,filtroMr,deembys }) => {
    const [personas, setPersonas] = useState([]);
    const navigate = useNavigate(); 
    const UsuarioId = localStorage.getItem('id_user');

    useEffect(() => {
        if (filtro !== null || filtroMr !== null) {
          GetCandidatos(filtro,filtroMr);
        }
    }, [filtro, filtroMr]);

    const GetCandidatos = (filtro,filtroMr) => {
        const data = {'type':'GetTop10','id_usuario':UsuarioId,filtro,filtroMr};
        fetch('https://novomediaapp.azurewebsites.net/InfoCandidatosProv.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(data => {
            setPersonas(data.data);
        })
        .catch(error => console.error('Error de red:', error));
    };  

    const handleProfileClick = (candidato) => {
        setcandidato(candidato);
        navigate(`/Candidato`);
    };

    return (
        <div style={styles.container2}>
            <>
                <div>
                    <Sidebar setFiltro={setFiltro} filtro={filtro} deembys={deembys}/>
                </div>
                <div style={styles.carousel}>
                    <br/>
                    <p style={{fontSize:'20px'}}>Top 10</p>
                    <div style={styles.container}>
                        {personas.map((persona,index) => (
                            <div key={persona.usuario_id} style={styles.personaContainer}>
                                <div style={styles.imageContainer}>
                                    <div style={{ border: '2px solid #01456A', borderRadius: '5px'}}>
                                        <img src={persona.foto} alt="Imagen de persona" style={styles.image} />
                                    </div>
                                    <div style={styles.numberContainer}>
                                        <p style={styles.numberText}>{index+1}</p>
                                    </div>
                                </div>
                                <div style={styles.infoContainer} onClick={() => handleProfileClick(persona)}>
                                    <p style={styles.puesto}>{persona.puesto} - 
                                        {persona.ejecutivo ==='1'?' Ejecutivo'
                                        :persona.legislativo ==='1'?' Legislativo'
                                        :persona.gabinete ==='1'?' Gabinete'
                                        :' Judicial'}
                                    </p>
                                    <p style={styles.nombre}>{persona.nombre}</p>
                                    <p style={styles.apellido}>{persona.apellidos}</p>
                                    <img src={seguidores} alt="Imagen de persona" style={{width:'3%'}} />
                                    <span style={{color: '#20C997'}}>{persona.seguidores}</span>
                                    <img src={deembysIMG} alt="Imagen de persona" style={{width:'3%', paddingLeft:'3%'}} />
                                    <span style={{color: '#20C997'}}>{persona.deembys}</span>
                                </div>
                                <br/>
                            </div>
                        ))}
                    </div>
                </div>
            </>
        </div>
    );
};

const styles = {
    container: {
        margin: '0 20px', 
    },
    container2: {
        display: 'grid',
        gridTemplateColumns: '30% 70%',
        paddingTop:'2%'
    },
    personaContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '30px',
    },
    imageContainer: {
        position: 'relative', 
        width: '100px',
        height: '100px',
        borderRadius:'1px',
        marginRight: '10px',
        overflow: 'hidden', 
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    infoContainer: {
        flex: 1,
        cursor:'pointer',
    },
    puesto: {
        margin: 0,
        color: '#aaa',
    },
    nombre: {
        margin: 0,
        fontWeight: 'bold',
    },
    apellido: {
        margin: 0,
    },
    numberContainer: {
        position: 'absolute',
        bottom: '0',
        left: '0',
        backgroundColor: '#01456A', // Color de fondo azul
        color: '#fff', // Color del texto blanco
        padding: '5px',
        borderRadius: '5px',
        borderBottomRightRadius: '0',
        borderTopLeftRadius: '0',
    },
    numberText: {
        margin: 0,
    },
};

export default Top10;
