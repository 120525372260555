import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import deembys from './imagenes/dem.png'
import checkIcon from './imagenes/check.png'
import NocheckIcon from './imagenes/nocheck.png'
import seguidores from './imagenes/seguidores.png'
import Filtro from './Filtro'
import { useNavigate } from 'react-router-dom'; 

const PersonList = ({setcandidato,filtro,setFiltroMr,filtroMr,partido,setPartido}) => {
  const [personas, setPersonas] = useState([]);
  const [compara1, setCompara1] = useState([]);
  const [compara2, setCompara2] = useState([]);
  const [isModal, SetModal] = useState(false);
  const navigate = useNavigate(); 
  const UsuarioId = localStorage.getItem('id_user');

  useEffect(() => {
    if (filtro !== null && filtroMr !== null && partido !== null) {
      GetCandidatos(filtro);
    }
  }, [filtro,filtroMr,partido]);

  const comparar = (candidato) => {
    if(compara1.id>0){
      setCompara2(candidato);
    }else{
      setCompara1(candidato);
    }

    if(compara1.id>0){
      SetModal(true);
    }
  }

  const vibe = () => {
    alert('El usuario esta inactivo');
  }

  const CloseModal= () => {
    SetModal(false);
    setCompara2([]);
    setCompara1([]);
  }

  const GetCandidatos = (filtro) => {
    setPersonas([]);
    const data = {'type':'GetCandidatos','id_usuario':UsuarioId,filtro,filtroMr,partido}
    fetch('https://novomediaapp.azurewebsites.net/InfoCandidatosProv.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(data => {
      setPersonas(data.data);
    })
    .catch(error => console.error('Error de red:', error));
  }  

  const seguir_Usuario = (idSeguir,status) => {
    const data = {
      'type':'Seguir_Usuario',
      'id_usuario':UsuarioId,
      'seguir':idSeguir,
      'status':status,
    }
    fetch('https://novomediaapp.azurewebsites.net/InfoCandidatosProv.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(data => {
      GetCandidatos(filtro);
    })
    .catch(error => console.error('Error de red:', error));
  }  

  const handleProfileClick = (candidato) => {
    setcandidato(candidato);
    navigate(`/Candidato`);
  };

  return (
    <>
    <br/>
    <p style={{fontSize:'20px'}}>Tus Candidat@s</p>
    {filtro===3||filtro===4?<Filtro filtroMr={filtroMr} filtro={filtro} setFiltroMr={setFiltroMr} setPartido={setPartido}/>:''}
    <div style={styles.container}>
      {personas.map((persona) => (
        <div key={persona.usuario_id} style={styles.personaContainer}>
          <div style={styles.imageContainer}>
            <img src={persona.foto} alt="Imagen de persona" style={styles.image} onClick={() => handleProfileClick(persona)}/>
          </div>
          <div style={styles.infoContainer} >
            <p style={styles.puesto}>{persona.puesto} - 
              {filtro===4?persona.circunscripcion:persona.entidad
              /*persona.presidencia ==='1'?' Presidencia'
              :persona.gobernatura ==='1'?' Gobernatura'
              :persona.senaduria ==='1'?' Senaduria'
              :persona.diputacion ==='1'?' Diputacion'
              :' Alcaldia'*/}
            </p>
            <p style={styles.nombre} onClick={() => handleProfileClick(persona)}>{persona.nombre}</p>
            <p style={styles.apellido} onClick={() => handleProfileClick(persona)}>{persona.apellidos}</p>
            <img src={seguidores} alt="Imagen de persona" style={{width:'16px'}} /><span style={{color: '#20C997'}}>{persona.seguidores}</span>
            <img src={deembys} alt="Imagen de persona" style={{ width: '24px',marginLeft: '4px' }} /><span style={{color: '#20C997'}}>{persona.dembys}</span>
            {persona.facebook && (
              <a href={persona.facebook} target="_blank" rel="noopener noreferrer" style={{ paddingLeft: '3%' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
                  <path d="M18 2H15C13.6739 2 12.4021 2.52678 11.4645 3.46447C10.5268 4.40215 10 5.67392 10 7V10H7V14H10V22H14V14H17L18 10H14V7C14 6.73478 14.1054 6.48043 14.2929 6.29289C14.4804 6.10536 14.7348 6 15 6H18V2Z" stroke="#235A7B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
              </a>
            )}
            {persona.twitter && (
              <a href={persona.twitter} target="_blank" rel="noopener noreferrer" style={{ paddingLeft: '3%' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
                  <path d="M14.2737 10.1635L23.2022 0H21.0872L13.3312 8.82302L7.14122 0H0L9.36257 13.3432L0 23.9999H2.11503L10.3002 14.6805L16.8387 23.9999H23.9799M2.87839 1.56189H6.12767L21.0856 22.5148H17.8355" stroke="#235A7B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
              </a>
            )}
            {persona.instagram && (
              <a href={persona.instagram} target="_blank" rel="noopener noreferrer" style={{ paddingLeft: '3%' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
                  <path d="M17 2H7C4.23858 2 2 4.23858 2 7V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V7C22 4.23858 19.7614 2 17 2Z" stroke="#235A7B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M16.0002 11.37C16.1236 12.2022 15.9815 13.0522 15.594 13.799C15.2065 14.5458 14.5933 15.1514 13.8418 15.5297C13.0903 15.9079 12.2386 16.0396 11.408 15.9059C10.5773 15.7723 9.80996 15.3801 9.21503 14.7852C8.62011 14.1902 8.22793 13.4229 8.09426 12.5922C7.9606 11.7615 8.09226 10.9099 8.47052 10.1584C8.84878 9.40685 9.45438 8.79374 10.2012 8.40624C10.948 8.01874 11.7979 7.87658 12.6302 8C13.4791 8.12588 14.265 8.52146 14.8719 9.1283C15.4787 9.73515 15.8743 10.5211 16.0002 11.37Z" stroke="#235A7B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M17.5 6.5H17.51" stroke="#235A7B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
              </a>
            )}
            <img src={persona.logo_partido} alt="Imagen de persona" style={{ height: '20px',width:'auto', paddingLeft: '3%' }} />
          </div>
          <div style={styles.iconsContainer}>
            {persona.user_id>0?
              persona.siguiendo==='1' ? 
              <button style={styles.buttonSiguiendo} onClick={() => seguir_Usuario(persona.user_id, 0)}>Siguiendo</button> 
              : 
              <button style={styles.buttonSeguir} onClick={() => seguir_Usuario(persona.user_id, 1)}>Seguir</button>
            :<button style={styles.buttonSeguir} onClick={() => alert("Este perfil no está activo")}>Seguir</button>
            }
            <button style={{...styles.buttonComparar, 
              color:compara1.id===persona.id ||compara2.id ===persona.id?'white':'' , 
              backgroundColor:compara1.id===persona.id ||compara2.id ===persona.id?'#01456A':'' }} 
              onClick={() => comparar(persona)}>Comparar</button>
          </div>
          <br/>
        </div>
      ))}

      <Modal isOpen={isModal} onRequestClose={CloseModal} style={customStyles}>
        <div style={styles.modalContainer}>
          <div style={styles.leftPane}>
            <p style={{ marginBottom: '10px',fontSize:'20px' }}>
              {compara1.nombre} <b>{compara1.apellidos}</b>
              <br/><br/>
            </p>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <img src={compara1.foto} alt="Foto" style={{ width: '100px', height: '100px', borderRadius: '50%', marginRight: '10%',border: '2px solid #01456A', }} />
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginLeft: '10px' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img src={seguidores} alt="Seguidores" style={{ width: '20px' }} />
                  <span style={{ color: '#20C997', marginLeft: '5px' }}>{compara1.seguidores}</span>
                  <img src={deembys} alt="deembys" style={{ width: '20px', marginLeft: '10px' }} />
                  <span style={{ color: '#20C997', marginLeft: '5px' }}>{compara1.deembys}</span>
                </div>
                {/*<button style={{ ...styles.buttonSeguir, marginTop: '10px', width: '200px' }} onClick={() => vibe(compara1.usuario_id)}>+ Vibe</button>*/}
              </div>
            </div>
            <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ textAlign: 'left' }}>
                <p style={{color:'#495057'}}>Sexo</p>
                <p style={{color:'#495057'}}>Edad</p>
                <p style={{color:'#495057'}}>Escolaridad</p>
                <p style={{color:'#495057'}}>Estatus de Escolaridad</p>
              </div>
              <div style={{ textAlign: 'right' }}>
                <p>{compara1.sexo}</p>
                <p>{compara1.edad+ ' años'}</p>
                <p>{compara1.escolaridad} </p>
                <p>{compara1.estatus_escolaridad} </p>
              </div>
            </div>
            <p style={{fontSize:'25px'}}>Experiencia</p>
            <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ textAlign: 'left' }}>
                <p>Legislativa</p>
                <p>Gubernamental</p>
                <p>En Partidos</p>
              </div>
              <div style={{ textAlign: 'right' }}>
                <p>{compara1.experiencia_legislativa?<img src={checkIcon} alt="check" style={{width:'8%'}}/>:<img src={NocheckIcon} alt="check" style={{width:'8%'}}/>}</p>
                <p>{compara1.experiencia_gubernamental?<img src={checkIcon} alt="check" style={{width:'8%'}}/>:<img src={NocheckIcon} alt="check" style={{width:'8%'}}/>}</p>
                <p>{compara1.experiencia_partidos?<img src={checkIcon} alt="check" style={{width:'8%'}}/>:<img src={NocheckIcon} alt="check" style={{width:'8%'}}/>}</p>
              </div>
            </div>
          </div>
          <div style={styles.rightPane}>
            <p style={{ marginBottom: '10px',fontSize:'20px' }}>
              {compara2.nombre} <b>{compara2.apellidos}</b>
              <br/><br/>
            </p>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <img src={compara2.foto} alt="Foto" style={{ width: '100px', height: '100px', borderRadius: '50%', marginRight: '10%', border: '2px solid #01456A',}} />
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginLeft: '10px' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img src={seguidores} alt="Seguidores" style={{ width: '20px' }} />
                  <span style={{ color: '#20C997', marginLeft: '5px' }}>{compara2.seguidores}</span>
                  <img src={deembys} alt="deembys" style={{ width: '20px', marginLeft: '10px' }} />
                  <span style={{ color: '#20C997', marginLeft: '5px' }}>{compara2.deembys}</span>
                </div>
                {/*<button style={{ ...styles.buttonSeguir, marginTop: '10px', width: '200px' }} onClick={() => vibe(compara2.usuario_id)}>+ Vibe</button>*/}
              </div>
            </div>
            <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ textAlign: 'left' }}>
                <p style={{color:'#495057'}}>Sexo</p>
                <p style={{color:'#495057'}}>Edad</p>
                <p style={{color:'#495057'}}>Escolaridad</p>
                <p style={{color:'#495057'}}>Estatus de Escolaridad</p>
              </div>
              <div style={{ textAlign: 'right' }}>
                <p>{compara2.sexo}</p>
                <p>{compara2.edad+ ' años'}</p>
                <p>{compara2.escolaridad}</p>
                <p>{compara2.estatus_escolaridad} </p>
              </div>
            </div>
            <p style={{fontSize:'25px'}}>Experiencia</p>
            <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ textAlign: 'left' }}>
                <p>Legislativa</p>
                <p>Gubernamental</p>
                <p>En Partidos</p>
              </div>
              <div style={{ textAlign: 'right' }}>
                <p>{compara2.experiencia_legislativa==='1'?<img src={checkIcon} alt="check" style={{width:'8%'}}/>:<img src={NocheckIcon} alt="check" style={{width:'8%'}}/>}</p>
                <p>{compara2.experiencia_gubernamental==='1'?<img src={checkIcon} alt="check" style={{width:'8%'}}/>:<img src={NocheckIcon} alt="check" style={{width:'8%'}}/>}</p>
                <p>{compara2.experiencia_partidos==='1'?<img src={checkIcon} alt="check" style={{width:'8%'}}/>:<img src={NocheckIcon} alt="check" style={{width:'8%'}}/>}</p>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
    </>
    
  );
};

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const styles = {
  modalContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  leftPane: {
    width: '50%',
    height: '100%',
    padding:'3%' 
  },
  rightPane: {
    width: '50%',
    height: '100%',
    padding:'3%' 
  },
  container: {
    margin: '0 20px', 
    maxHeight: '400px', 
    overflowY: 'auto',
  },
  personaContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
  },
  imageContainer: {
    width: '100px',
    height: '100px',
    borderRadius:'50%',
    marginRight: '10px',
    overflow: 'hidden', 
  },
  
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    cursor:'pointer'
  },
  infoContainer: {
    flex: 1,
    cursor:'pointer',
  },
  puesto: {
    margin: 0,
    color: '#aaa',
  },
  nombre: {
    margin: 0,
    fontWeight: 'bold',
  },
  apellido: {
    margin: 0,
  },
  iconsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    cursor: 'pointer',
    fontSize: '1.5rem',
    color: '#5D7CF9',
  },
  buttonSeguir: {
    width: '100%',
    padding: '15px',
    borderRadius: '5px',
    backgroundColor: '#01456A',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    marginRight: '10px',
  },
  buttonVibe: {
    width: '100%',
    padding: '15px',
    borderRadius: '5px',
    backgroundColor: '#01456A',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    marginRight: '10px',
  },
  buttonComparar: {
    width: '100%',
    padding: '15px',
    borderRadius: '5px',
    backgroundColor: 'white',
    color: '#01456A',
    border: '1px solid #01456A',
    cursor: 'pointer',
    marginRight: '10px',
  },
  buttonSiguiendo: {
    width: '100%',
    padding: '15px',
    borderRadius: '5px',
    backgroundColor: '#20C997',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    marginRight: '10px',
  },
  iconAmigos: {
    marginLeft: '10px',
    width: '24px',
    height: '24px',
    backgroundColor: '#ccc',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export default PersonList;
