import React, { useEffect,useState } from 'react';
import StoryCarouselDes from './StoryCarouselDes';
import feed from './imagenes/feed.png'
import Sidebar from './Sidebar';
import PersonListDesc from './PersonListDesc';

const Main = (props) => {
  const [publicaciones, setPublicaciones] = useState([]);
  const id_user = localStorage.getItem('id_user');

  useEffect(() => {
    getPublicaciones(id_user);
  }, []);
  
  const getPublicaciones = (IdUser) => {
    const data = {
      'type':'GetPublicaciones',
      'IdUser':IdUser
    } 
    fetch('https://novomediaapp.azurewebsites.net/InfoCandidatosProv.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setPublicaciones(data.data);
          console.log(data.data);
        } 
      })
      .catch(error => console.error('Error de red:', error));
  }

  return (
    <div style={styles.container}>
      <>
        <div>
          
        </div>
        <div style={{...styles.carousel,paddingLeft:'35%',paddingBottom:'2%'}}>
          <img src={feed} style={{width: '28%'}}/>
        </div>
      </>
      <>
        <div>
          <Sidebar setFiltro={props.setFiltro} filtro={props.filtro} deembys={props.deembys}/>
        </div>
        <div style={styles.carousel}>
          <StoryCarouselDes publicaciones={publicaciones} getPublicaciones={getPublicaciones} deembys={props.deembys} getDeembysDiarios={props.getDeembysDiarios}/>
          <PersonListDesc partido={props.partido} setPartido={props.setPartido} setcandidato={props.setcandidato} filtro={props.filtro} filtroMr={props.filtroMr} setFiltroMr={props.setFiltroMr}/>
        </div>
      </>
    </div>
  );
};

const styles = {
  container: {
    display: 'grid',
    gridTemplateColumns: '30% 70%',
  },
  sidebar: {
    backgroundColor: '#fff',
  }
};

export default Main;
