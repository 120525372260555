import React, { useState } from 'react';
import home from './imagenes/home.png'
import star from './imagenes/start.png'
import colmena from './imagenes/colmenaN.png'
import hexa from './imagenes/HexaPerfiles.svg'
import { useNavigate } from 'react-router-dom'; 

const Sidebar = ({ setFiltro, filtro, deembys }) => {
  const navigate = useNavigate(); 

  const deembyReturn = () => {
    window.location.href = 'https://deemby.org';
  }

  const handleOptionClick = (filtroId) => {
    setFiltro(filtroId);
  };

  function handleOptionClickR() {
    window.location.href = 'https://infocandidatos.org';
  }
  return (
    <div style={styles.sidebar}>
      <div style={styles.option}>
        <div style={styles.logoContainer}>
          <img src={home} style={styles.logo} alt='Deemby'/>
        </div>
        <div style={styles.optionText} onClick={handleOptionClickR}>
          <p style={styles.optionLabel}>Home</p>
        </div>
      </div>
      <div style={{...styles.option, backgroundColor: filtro === 1 ? '#01456A' : 'white', color: filtro === 1 ? 'white' : 'black'}} onClick={() => handleOptionClick(1)}>
        <div style={styles.optionText2}>
          <p style={styles.optionLabel}>Presidencia</p>
          <div style={{ position: 'relative', ...styles.notification }}>
            <img src={hexa}/>
            <span style={{
              position: 'absolute',
              right: '-0%', // Ajusta este valor según sea necesario para la posición horizontal
              top: '10%', // Ajusta este valor según sea necesario para la posición horizontal
              color: 'white', // Color del texto del número
              borderRadius: '50%', // Forma redondeada para que sea un círculo
              width: '20px', // Ancho del número
              height: '20px', // Alto del número
              textAlign: 'center', // Alineación del texto al centro
              lineHeight: '20px', // Altura de línea igual a la altura del número para centrar verticalmente
              fontSize: '12px', // Tamaño de fuente del número
            }}>
              {deembys[0] && deembys[0].cantidad_registros > 0 ? 0 : 1}
            </span>
          </div>
        </div>
      </div>
      <div style={{...styles.option, backgroundColor: filtro === 2 ? '#01456A' : 'white', color: filtro === 2 ? 'white' : 'black'}} onClick={() => handleOptionClick(2)}>
        <div style={styles.optionText2}>
          <p style={styles.optionLabel}>Gubernatura</p>
          <div style={{ position: 'relative', ...styles.notification }}>
            <img src={hexa}/>
            <span style={{
              position: 'absolute',
              right: '-0%', // Ajusta este valor según sea necesario para la posición horizontal
              top: '10%', // Ajusta este valor según sea necesario para la posición horizontal
              color: 'white', // Color del texto del número
              borderRadius: '50%', // Forma redondeada para que sea un círculo
              width: '20px', // Ancho del número
              height: '20px', // Alto del número
              textAlign: 'center', // Alineación del texto al centro
              lineHeight: '20px', // Altura de línea igual a la altura del número para centrar verticalmente
              fontSize: '12px', // Tamaño de fuente del número
            }}>
              {deembys[1] && deembys[1].cantidad_registros > 0 ? 0 : 1}
            </span>
          </div>
        </div>
      </div>
      <div style={{...styles.option, backgroundColor: filtro === 3 ? '#01456A' : 'white', color: filtro === 3 ? 'white' : 'black'}} onClick={() => handleOptionClick(3)}>
        <div style={styles.optionText2}>
          <p style={styles.optionLabel}>Senaduría</p>
          <div style={{ position: 'relative', ...styles.notification }}>
            <img src={hexa}/>
            <span style={{
              position: 'absolute',
              right: '-0%', // Ajusta este valor según sea necesario para la posición horizontal
              top: '10%', // Ajusta este valor según sea necesario para la posición horizontal
              color: 'white', // Color del texto del número
              borderRadius: '50%', // Forma redondeada para que sea un círculo
              width: '20px', // Ancho del número
              height: '20px', // Alto del número
              textAlign: 'center', // Alineación del texto al centro
              lineHeight: '20px', // Altura de línea igual a la altura del número para centrar verticalmente
              fontSize: '12px', // Tamaño de fuente del número
            }}>
              {deembys[2] && deembys[2].cantidad_registros > 0 ? 0 : 1}
            </span>
          </div>
        </div>
      </div>
      <div style={{...styles.option, backgroundColor: filtro === 4 ? '#01456A' : 'white', color: filtro === 4 ? 'white' : 'black'}} onClick={() => handleOptionClick(4)}>
        <div style={styles.optionText2}>
          <p style={styles.optionLabel}>Diputación</p>
          <div style={{ position: 'relative', ...styles.notification }}>
            <img src={hexa}/>
            <span style={{
              position: 'absolute',
              right: '-0%', // Ajusta este valor según sea necesario para la posición horizontal
              top: '10%', // Ajusta este valor según sea necesario para la posición horizontal
              color: 'white', // Color del texto del número
              borderRadius: '50%', // Forma redondeada para que sea un círculo
              width: '20px', // Ancho del número
              height: '20px', // Alto del número
              textAlign: 'center', // Alineación del texto al centro
              lineHeight: '20px', // Altura de línea igual a la altura del número para centrar verticalmente
              fontSize: '12px', // Tamaño de fuente del número
            }}>
              {deembys[3] && deembys[3].cantidad_registros > 0 ? 0 : 1}
            </span>
          </div>
        </div>
      </div>
      <div style={{...styles.option, backgroundColor: filtro === 5 ? '#01456A' : 'white', color: filtro === 5 ? 'white' : 'black'}} onClick={() => handleOptionClick(5)}>
        <div style={styles.optionText2}>
          <p style={styles.optionLabel}>Alcaldía / Municipio</p>
          <div style={{ position: 'relative', ...styles.notification }}>
            <img src={hexa}/>
            <span style={{
              position: 'absolute',
              right: '-0%', // Ajusta este valor según sea necesario para la posición horizontal
              top: '10%', // Ajusta este valor según sea necesario para la posición horizontal
              color: 'white', // Color del texto del número
              borderRadius: '50%', // Forma redondeada para que sea un círculo
              width: '20px', // Ancho del número
              height: '20px', // Alto del número
              textAlign: 'center', // Alineación del texto al centro
              lineHeight: '20px', // Altura de línea igual a la altura del número para centrar verticalmente
              fontSize: '12px', // Tamaño de fuente del número
            }}>
              {deembys[4] && deembys[4].cantidad_registros > 0 ? 0 : 1}
            </span>
          </div>
        </div>
      </div>
      <div style={styles.option}>
        <div style={styles.logoContainer}>
        <img src={star} style={styles.logo} alt='Deemby'/>
        </div>
        <div style={styles.optionText} onClick={() => navigate('/Top10')}> 
          <p style={styles.optionLabel}>Top 10</p>
        </div>
      </div>
      <div style={styles.option}>
        <div style={styles.logoContainer}>
          <img src={colmena} style={styles.logo} alt='Deemby'/>
        </div>
        <div style={styles.optionText} onClick={() => deembyReturn()}>
          <p style={styles.optionLabel}>Hive</p>
        </div>
      </div>
    </div>
  );
};

const styles = {
  sidebar: {
    backgroundColor: 'white',
    padding: '20px',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  option: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px', 
    cursor:'pointer'
  },
  logoContainer: {
    marginRight: '10px',
    textAlign: 'center',
    color:'black' 
  },
  logo: {
    width: '30px',
    height: '30px',
  },
  optionText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft:'2%',
    width: '100%',
    cursor:'pointer'
  },
  optionText2: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft:'15%',
    width: '100%',
  },
  optionLabel: {
    fontSize: '14px', 
    margin: 0, 
  },
  notification: {
    width: '20px',
    height: '20px',
    textAlign: 'center',
    fontSize: '12px',
    lineHeight: '20px',
    marginRight: '45%',
  },
  notificationNumber: {
    fontSize: '11px', // Tamaño de fuente reducido para el número de notificación
  },
};

export default Sidebar;
