import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import { FaTimes } from 'react-icons/fa';
import { Loader } from '@googlemaps/js-api-loader';
import plusIcon from './imagenes/UbicaTuCasilla.svg';

const ModalCasilla = ({ isOpen, onRequestClose }) => {
  const [casillas, setCasillas] = useState([]);
  const [insignia, setInsignia] = useState([]);
  const [userLocation, setUserLocation] = useState(null);
  const mapRef = useRef(null);
  const id_user = localStorage.getItem('id_user');
  const googleMapRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      fetchCasillas();
      getUserLocation();
      getBadge();
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && casillas.length > 0) {
      loadMap();
    }
  }, [isOpen, casillas]);

  const getBadge = () => {
    const data = {
      type: 'GetBadge',
      id_user,
      badge: 1
    };

    fetch('https://novomediaapp.azurewebsites.net/deemby.php', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(data => {
        setInsignia(data.data[0].insigniaid);
      })
      .catch(error => {
        console.error('Fetch error:', error);
      });
  };

  const getUserLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const userLocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        setUserLocation(userLocation);
      },
      (error) => {
        console.error('Error getting user location:', error);
      }
    );
  };

  const fetchCasillas = async () => {
    const data = {
      type: 'GetCasillas',
      id_user,
    };
    try {
      const response = await fetch('https://novomediaapp.azurewebsites.net/InfoCandidatosProv.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      const result = await response.json();
      if (result.success) {
        setCasillas(result.data);
      } else {
        console.error('Error fetching casillas:', result.message);
      }
    } catch (error) {
      console.error('Error de red:', error);
    }
  };

  const loadMap = () => {
    const loader = new Loader({
      apiKey: 'AIzaSyDqesyfPw3y1pU2BW_V86_seF4i9Xem65A',
      version: 'weekly',
    });

    loader.load().then(() => {
      if (window.google) {
        const map = new window.google.maps.Map(mapRef.current, {
          center: { lat: parseFloat(casillas[0].latitud_google), lng: parseFloat(casillas[0].longitud_google) },
          zoom: 13,
        });

        googleMapRef.current = map;
        addMarkers(map);
      } else {
        console.error('Google Maps API failed to load');
      }
    });
  };

  const haversineDistance = (coords1, coords2) => {
    function toRad(x) {
      return x * Math.PI / 180;
    }
    const R = 6371; // Radio de la Tierra en kilómetros
    const dLat = toRad(coords2.lat - coords1.lat);
    const dLng = toRad(coords2.lng - coords1.lng);
    const lat1 = toRad(coords1.lat);
    const lat2 = toRad(coords2.lat);

    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLng / 2) * Math.sin(dLng / 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distancia en km
    console.log(d* 1000);
    return d * 1000; // Distancia en metros
  };

  const addMarkers = (map) => {
    casillas.forEach((casilla) => {
      const marker = new window.google.maps.Marker({
        position: { lat: parseFloat(casilla.latitud_google), lng: parseFloat(casilla.longitud_google) },
        map,
        title: casilla.domicilio,
        icon: {
          url: plusIcon,
          scaledSize: new window.google.maps.Size(30, 30), // Ajusta el tamaño del icono aquí
        },
      });

      const infowindowContent = `
        <div>
          <h5>Casilla: ${casilla.casilla}</h5> 
          ${insignia > 0 ?
            '<b>Tu ya tienes esta insignia<b> <img src="https://novomediaapp.azurewebsites.net/assents/Badges/'+insignia+'.svg" height="30px" alt="Insignia">' 
            : 
            '<button id="checkRangeBtn">Obtener Insignia</button>'
          }
          <br/><br/><br/>
          <strong>Dirección:</strong> ${casilla.domicilio}
          <div id="rangeMessage"></div>
        </div>`;

      const infowindow = new window.google.maps.InfoWindow({
        content: infowindowContent,
      });

      marker.addListener('click', () => {
        infowindow.open(map, marker);

        window.google.maps.event.addListenerOnce(infowindow, 'domready', () => {
          const checkRangeBtn = document.getElementById('checkRangeBtn');
          if (checkRangeBtn) {
            checkRangeBtn.addEventListener('click', () => {
              if (userLocation) {
                const distance = haversineDistance(userLocation, { lat: parseFloat(casilla.latitud_google), lng: parseFloat(casilla.longitud_google) });
                const rangeMessage = document.getElementById('rangeMessage');

                if (distance <= 50) {
                  const data = {
                    type: 'SetBadge',
                    id_user,
                    badge: 1
                  };

                  fetch('https://novomediaapp.azurewebsites.net/deemby.php', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(data)
                  })
                    .then(response => response.json())
                    .then(data => {
                      if (data.success) {
                        rangeMessage.innerHTML = '<p style="color: green;">' + data.message + '</p>';
                      } else {
                        rangeMessage.innerHTML = '<p style="color: red;">' + data.message + '</p>';
                      }

                    })
                    .catch(error => {
                      console.error('Fetch error:', error);
                    });
                } else {
                  rangeMessage.innerHTML = '<p style="color: red;">No estás cerca de la casilla asignada.</p>';
                }
              } else {
                console.error('User location not available');
              }
            });
          }
        });
      });
    });
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={customStyles}>
      <div style={styles.header}>
        <h2>Ubica tu Casilla</h2>
        <FaTimes onClick={onRequestClose} style={styles.closeIcon} />
      </div>
      <div ref={mapRef} style={{ height: '87%', width: '100%' }}></div>
    </Modal>
  );
};

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '80%',
  },
};

const styles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
  },
  closeIcon: {
    cursor: 'pointer',
    fontSize: '20px',
  },
};

export default ModalCasilla;