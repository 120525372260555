import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; 

const ListPost = ({setPost}) => {
  const [posts, setPosts] = useState([]);
  const id_user = localStorage.getItem('id_user');
  const organizacion = localStorage.getItem('organizacion');
  const navigate = useNavigate(); 

  useEffect(() => {
    myPost();
  }, []);

  const myPost = () => {
    const data = {'type':'GetPostUser',organizacion}
    fetch('https://novomediaapp.azurewebsites.net/InfoCandidatosProv.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', 
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(data => {
        setPosts(data.data);
      })
      .catch(error => console.error('Error de red:', error));
  };

  const editPost = (post) => {
    setPost(post);
    navigate(`/EditPost`);
  };
  
  return (
    <div>
      <button className='btn btn-info' style={{marginLeft:'2%'}} onClick={() => navigate('/NewPost')}>Publicar</button>
      {posts.map((post) => (
        <div className="col-md-12" style={{paddingTop:'10%',cursor:'pointer',border:1}}  onClick={() => editPost(post)}>
        <h4>{post.titulo}</h4>
        <p>{post.nota}</p>
        <p>{post.link}</p>
        <i>Fecha Publicacion: {post.fecha_publicacion}</i>
        <hr/>
      </div>
      ))}
    </div>
  );
};

export default ListPost;